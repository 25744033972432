import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { HTTP_STATUS, httpStatusDefaultErrorMessages } from '../enums/httpStatus';
import { showToast } from '../helpers/toastHelpers';
import { getTrans } from '../helpers/getTrans';
import { ApiErrorResponse } from '../types/types';

const BASE_URL = '/api';
const GENERIC_ERROR_MESSAGE = 'An error occurred while making the request.';

type ConditionalAxiosRequestConfig = AxiosRequestConfig & {
    showError?: boolean;
    showSuccess?: boolean;
};

interface AxiosRepositoryOptions {
    params?: Record<string, unknown>;
    config?: ConditionalAxiosRequestConfig;
    showError?: boolean;
    showSuccess?: boolean;
    useBaseURL?: boolean;
    successMessage?: string;
}

interface AxiosRepository {
    get<T>(endpoint: string, options?: AxiosRepositoryOptions): Promise<T>;
    post<T>(endpoint: string, data: unknown, options?: AxiosRepositoryOptions): Promise<T>;
    patch<T>(endpoint: string, data: unknown, options?: AxiosRepositoryOptions): Promise<T>;
    put<T>(endpoint: string, data: unknown, options?: AxiosRepositoryOptions): Promise<T>;
    delete<T>(endpoint: string, options?: AxiosRepositoryOptions): Promise<T>;
}

export default function useAxiosRepository(): AxiosRepository {
    let stopRequestsOn401 = false;

    function handleAxiosError(error: AxiosError<ApiErrorResponse>, showError = false): void {
        const { status, data } = error.response;
        const errorMessage = data.message;
        const toastMessage = `${errorMessage || httpStatusDefaultErrorMessages[status] || GENERIC_ERROR_MESSAGE}`;

        switch (status) {
            case HTTP_STATUS.TOO_MANY_REQUESTS:
            case HTTP_STATUS.UNAUTHORIZED:
            case HTTP_STATUS.FORBIDDEN:
            case HTTP_STATUS.GATEWAY_TIMEOUT:
                showToast({
                    message: toastMessage,
                });

                if (status === HTTP_STATUS.UNAUTHORIZED) {
                    stopRequestsOn401 = true;

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }

                break;
            case HTTP_STATUS.UNPROCESSABLE_ENTITY:
                if (showError && !data.errors) {
                    showToast({
                        message: toastMessage,
                    });
                }

                break;
            default:
                if (showError) {
                    showToast({
                        message: toastMessage,
                    });
                }

                break;
        }

        throw error;
    }

    async function makeRequest<T>(
        method: 'get' | 'post' | 'patch' | 'put' | 'delete',
        endpoint: string,
        data: Record<string, unknown> | null,
        options: AxiosRepositoryOptions = {},
    ): Promise<T> {
        const {
            config = {},
            showError = false,
            showSuccess = false,
            useBaseURL = true,
            successMessage = '',
        } = options;

        const customHeaders = {
            'X-Front-End-Request': 'true',
            ...config.headers,
        };

        if (stopRequestsOn401) {
            return Promise.reject(new Error('Request stopped due to a 401 error.'));
        }

        try {
            const url = useBaseURL ? `${BASE_URL}/${endpoint}` : endpoint;

            const response: AxiosResponse<T> = await axios.request({
                method,
                url,
                data,
                headers: customHeaders,
                ...config,
            });

            if (response.status < HTTP_STATUS.OK || response.status >= HTTP_STATUS.MULTIPLE_CHOICES) {
                throw new Error(`Request failed with status code ${response.status}`);
            }

            if (method === 'patch' || method === 'post' || method === 'put' || method === 'delete') {
                if (showSuccess || successMessage) {
                    showToast({
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        message: successMessage || (response.data.message ?? getTrans('quests-dashboard.successToast', 'Success')),
                        type: 'success',
                    });
                }
            }

            return response.data;
        } catch (error) {
            if (error.response) {
                handleAxiosError(error, showError);
            }

            throw error;
        }
    }

    return {
        get<T>(endpoint: string, options: AxiosRepositoryOptions = {}): Promise<T> {
            return makeRequest('get', endpoint, undefined, options);
        },

        post<T>(endpoint: string, data: Record<string, unknown> | undefined, options: AxiosRepositoryOptions = {}): Promise<T> {
            return makeRequest('post', endpoint, data, options);
        },

        patch<T>(endpoint: string, data: Record<string, unknown> | undefined, options: AxiosRepositoryOptions = {}): Promise<T> {
            return makeRequest('patch', endpoint, data, options);
        },

        put<T>(endpoint: string, data: Record<string, unknown> | undefined, options: AxiosRepositoryOptions = {}): Promise<T> {
            return makeRequest('put', endpoint, data, options);
        },

        delete<T>(endpoint: string, options: AxiosRepositoryOptions = {}): Promise<T> {
            return makeRequest('delete', endpoint, undefined, options);
        },
    };
}
